import { ApolloProvider } from '@apollo/client'
import { ClientOnly, useApiConfig } from '@upvio/next'
import {
  ToastGlobalProvider,
  ErrorView,
  Provider as AustralisProvider,
} from 'australis'
import App from 'next/app'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { ReactElement, ReactNode } from 'react'

import ApolloCacheResetter from 'src/components/ApolloCacheResetter'
import { HubSpotTrackingCode } from 'src/components/HubSpot/TrackingCode'
import LoadingBar from 'src/components/Loading/LoadingBar'
import { TelehealthPatientLayout } from 'src/layout/TelehealthPatientLayout/TelehealthPatientLayout'
import WithAuthentication from 'src/layout/WithAuthentication/WithAuthentication'
import { APP_ENV } from 'src/utils/constants'
import client from '../apollo-client'
import { GTMAppTag } from '../components/GoogleTagManager'
import styles from '../styles/app.module.scss'
import { bugsnagStart } from '../utils/bugsnag'

import type { NextPage } from 'next'
import type { AppContext, AppInitialProps, AppProps } from 'next/app'

import '../styles/globals.scss'
import 'australis/dist/esm/index.css'
import 'australis/dist/esm/foundation/index.css'
import 'australis/dist/esm/foundation/themes.css'
import '../components/Calendar/styles/index.scss'

const TelehealthClientLoader = dynamic(
  () => import('src/blocks/Zoom/components/ClientLoader'),
  {
    ssr: false,
  },
)

bugsnagStart()

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function AccountPortalApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page)
  const layout = getLayout(<Component {...pageProps} />)
  const config = useApiConfig()
  const router = useRouter()

  if (router.pathname.startsWith('/telehealth')) {
    return <TelehealthPatientLayout>{layout}</TelehealthPatientLayout>
  }

  return (
    <ClientOnly>
      <ApolloProvider client={client}>
        <Head>
          <title>{config.brandName} Account Portal</title>
          <meta name='robots' content='noindex, nofollow' />
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1, interactive-widget=resizes-content, viewport-fit=cover'
          />
          <link rel='icon' href={`/${config.brand}.favicon.png`} />
        </Head>
        <ErrorBoundary
          errorComponent={(props) => <ErrorView {...props} env={APP_ENV} />}
        >
          <GTMAppTag />
          <LoadingBar />
          <ApolloCacheResetter />
          <AustralisProvider navigate={router.push}>
            <WithAuthentication>
              <div
                className={`theme-${config.brand} ${styles['app-theme-wrapper']}`}
              >
                {layout}
              </div>
            </WithAuthentication>
          </AustralisProvider>
          <ToastGlobalProvider />
          <TelehealthClientLoader />
          <HubSpotTrackingCode />
        </ErrorBoundary>
      </ApolloProvider>
    </ClientOnly>
  )
}

/**
 * Since we're using `router.query` throughout the app, we need to make sure
 * that SSR is triggered for each, even though we don't want to use it.
 */
AccountPortalApp.getInitialProps = async (
  context: AppContext,
): Promise<AppInitialProps> => {
  const ctx = await App.getInitialProps(context)

  return {
    ...ctx,
  }
}

export default AccountPortalApp
