import Script from 'next/script'
import React from 'react'

/**
 * Used for tracking client usage and reporting to HubSpot.
 *
 * https://app-eu1.hubspot.com/settings/143628111/analytics-and-tracking/tracking
 */
export const HubSpotTrackingCode: React.FC = () => {
  return (
    <Script
      type='text/javascript'
      id='hs-script-loader'
      async
      defer
      src='//js-eu1.hs-scripts.com/143628111.js'
    />
  )
}
